// Module Imports
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
// Custom Imports
import Layout from "../components/layout"
import { Section, Container } from "../layout"
import { colors, spacing } from "../designsystem"

// Component
const MarkdownPageTemplate = ({ data: { markdownRemark: templateData } }) => (
  <Layout
    title={templateData.frontmatter.title}
    description={templateData.frontmatter.description}
    dontRenderMainMenu
  >
    <StyledHTMLSection>
      <Container dangerouslySetInnerHTML={{ __html: templateData.html }} />
    </StyledHTMLSection>
  </Layout>
)

// Styling (only for the inner section)
const StyledHTMLSection = styled(Section)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.primary.semidark};
    margin-bottom: ${spacing[1]};
    &:not(:first-child) {
      margin-top: ${spacing[3]};
    }
  }

  ul {
    margin-top: ${spacing[1]};
    padding-left: ${spacing[2]};
  }
`

// QueryPage (feeds the data-argument in component function)
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        description
        title
      }
    }
  }
`

// PropTypes
MarkdownPageTemplate.propTypes = {
  data: PropTypes.object,
}

export default MarkdownPageTemplate
